var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container__form" },
    [
      _c(
        "b-container",
        { staticClass: "bg-white", attrs: { fluid: "" } },
        [
          _c("h2", [_vm._v(_vm._s(_vm.$t("form.Title")))]),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "country-group", attrs: { cols: "3" } },
                [
                  _c("b", { staticClass: "text-left" }, [
                    _vm._v(_vm._s(_vm.$t("welcome.country")))
                  ]),
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.show, rounded: "sm" } },
                    [
                      _c("b-row", { staticClass: "flex-nowrap" }, [
                        _c(
                          "button",
                          {
                            staticClass: "card-country",
                            class: { active: _vm.isActive },
                            on: {
                              click: function($event) {
                                return _vm.countrySelected("MX")
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/mexico-flag.svg")
                              }
                            }),
                            _c("p", [_vm._v("Mexico")])
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "card-country",
                            class: { active: !_vm.isActive },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.countrySelected("US")
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/eeuu-flag.svg")
                              }
                            }),
                            _c("p", [_vm._v(_vm._s(_vm.$t("welcome.usa")))])
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "country-group text-left",
                  attrs: { cols: "4" }
                },
                [
                  _c("b", { staticClass: "text-left" }, [
                    _vm._v(_vm._s(_vm.$t("form.Program")))
                  ]),
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.show, rounded: "sm" } },
                    [
                      _c("b-form-group", {
                        staticClass: "card-program",
                        attrs: { label: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var ariaDescribedby = ref.ariaDescribedby
                              return [
                                _c("b-form-radio-group", {
                                  staticClass: "select-radio",
                                  attrs: {
                                    options: _vm.programs,
                                    "aria-describedby": ariaDescribedby,
                                    "button-variant":
                                      _vm.program === "A"
                                        ? "outline-primary"
                                        : _vm.program === "V"
                                        ? "outline-success"
                                        : "outline-info",
                                    size: "sm",
                                    name: "radio-btn-outline",
                                    buttons: ""
                                  },
                                  model: {
                                    value: _vm.program,
                                    callback: function($$v) {
                                      _vm.program = $$v
                                    },
                                    expression: "program"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  ["V", "A"].includes(_vm.program)
                    ? [
                        _c("b", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.$t("form.Score")))
                        ]),
                        _c(
                          "b-overlay",
                          { attrs: { show: _vm.show, rounded: "sm" } },
                          [
                            _c("b-form-group", {
                              staticClass: "card-program",
                              attrs: { label: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var ariaDescribedby = ref.ariaDescribedby
                                      return [
                                        _c("b-form-radio-group", {
                                          staticClass: "select-radio",
                                          attrs: {
                                            options: _vm.scores,
                                            "aria-describedby": ariaDescribedby,
                                            "button-variant":
                                              _vm.score === "Ye"
                                                ? "outline-warning"
                                                : _vm.score === "In"
                                                ? "outline-secondary"
                                                : "light",
                                            size: "sm",
                                            name: "radio-btn-outline",
                                            buttons: ""
                                          },
                                          model: {
                                            value: _vm.score,
                                            callback: function($$v) {
                                              _vm.score = $$v
                                            },
                                            expression: "score"
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2971952050
                              )
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _c("b", { staticClass: "text-left" }, [
                    _vm._v(_vm._s(_vm.$t("form.Language")))
                  ]),
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.show, rounded: "sm" } },
                    [
                      _c("b-form-group", {
                        staticClass: "card-program",
                        attrs: { label: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var ariaDescribedby = ref.ariaDescribedby
                              return [
                                _c("b-form-radio-group", {
                                  staticClass: "select-radio",
                                  attrs: {
                                    options: _vm.languages,
                                    "aria-describedby": ariaDescribedby,
                                    "button-variant": "outline-danger",
                                    size: "sm",
                                    name: "radio-btn-outline",
                                    buttons: ""
                                  },
                                  model: {
                                    value: _vm.language,
                                    callback: function($$v) {
                                      _vm.language = $$v
                                    },
                                    expression: "language"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "b-col",
                { staticClass: "country-group", attrs: { cols: "3" } },
                [
                  _c("p", { staticClass: "text-left" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("form.variables")))])
                  ]),
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "m-2 mt-4 dropdown-table",
                      attrs: {
                        id: "dropdown-text",
                        right: "",
                        text: "Variables"
                      }
                    },
                    [
                      _c("b-table", {
                        attrs: { striped: "", hover: "", items: _vm.variables }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.configRol.administrador.pestana.pdf.acciones.editor
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pl-0" },
                    [
                      _c("EditorForm", {
                        attrs: { editContent: _vm.editContent, height: 400 },
                        model: {
                          value: _vm.content,
                          callback: function($$v) {
                            _vm.content = $$v
                          },
                          expression: "content"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "b-row",
                [
                  _c("b-alert", { attrs: { show: "", dismissible: "" } }, [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("permissions.msgPermissionsPDF")))
                    ])
                  ])
                ],
                1
              ),
          _vm.program !== "M" &&
          _vm.configRol.administrador.pestana.pdf.acciones.imagenes
            ? _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c("b-col", [
                    _c(
                      "section",
                      { staticClass: "roadTable", attrs: { id: "road" } },
                      [
                        _c("caption", [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.$t("form.Images")) + "(Road)")
                          ])
                        ]),
                        _c("b-form-file", {
                          staticClass: "files",
                          attrs: {
                            multiple: "",
                            "file-name-formatter": _vm.formatNames
                          },
                          on: {
                            change: function($event) {
                              return _vm.upload($event)
                            }
                          }
                        }),
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.$t("form.Position")))
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.$t("form.FileName")))
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.$t("form.View")))
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.$t("form.Actions")))
                              ])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.imagenesRoad, function(imagen, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [
                                  _vm.formActualizar &&
                                  _vm.idActualizar == index
                                    ? _c(
                                        "span",
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              options: _vm.options,
                                              size: "sm"
                                            },
                                            model: {
                                              value: _vm.indexActualizar,
                                              callback: function($$v) {
                                                _vm.indexActualizar = $$v
                                              },
                                              expression: "indexActualizar"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          " " + _vm._s(imagen.posicion) + " "
                                        )
                                      ])
                                ]),
                                _c("td", [
                                  _vm._v(" " + _vm._s(imagen.filename) + " ")
                                ]),
                                _c("td", [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal",
                                              value: "modal-" + index,
                                              expression: "'modal-' + index"
                                            }
                                          ]
                                        },
                                        [_vm._v(_vm._s(_vm.$t("form.view")))]
                                      ),
                                      _c(
                                        "b-modal",
                                        {
                                          attrs: {
                                            size: "lg",
                                            id: "modal-" + index,
                                            title: imagen.filename
                                          }
                                        },
                                        [
                                          _c("img", {
                                            style: { width: "100%" },
                                            attrs: {
                                              src:
                                                "data:image/jpeg;base64," +
                                                imagen.base_64_file,
                                              alt: "Img"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _c("td", { staticClass: "column-actions" }, [
                                  _vm.formActualizar &&
                                  _vm.idActualizar == index
                                    ? _c("span", [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-success-custom",
                                            on: {
                                              click: function($event) {
                                                return _vm.guardarActualizacion(
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("form.Save")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    : _c("span", [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-blue-dark mr-2",
                                            on: {
                                              click: function($event) {
                                                return _vm.verFormActualizar(
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("form.Update")) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-danger-custom",
                                            on: {
                                              click: function($event) {
                                                return _vm.borrarImagen(index)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("form.Delete")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                ])
                              ])
                            }),
                            0
                          )
                        ]),
                        _c("hr")
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.configRol.administrador.pestana.pdf.acciones.guardar
            ? _c(
                "b-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "b-col",
                    { staticClass: "my-4" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-3 btn-blue-dark",
                          attrs: {
                            pill: "",
                            disabled:
                              !_vm.configRol.administrador.pestana.pdf.acciones
                                .editor &&
                              !_vm.configRol.administrador.pestana.pdf.acciones
                                .imagenes
                          },
                          on: { click: _vm.saveHTML }
                        },
                        [_vm._v(_vm._s(_vm.$t("form.Save")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }