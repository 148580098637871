<template>
  <div class="upload-container">
    
    <b-button size="sm" variant="info" @click="show=true"><b-icon icon="cloud-download" aria-hidden="true"></b-icon>{{ $t('form.uploadImage')}}</b-button>
    <b-modal
      v-model="show"
      :title="this.$i18n.t('form.uploadImage')"
      centered
      header-bg-variant="info"
      header-text-variant="light"
      body-bg-variant="light"
    >
      <b-container fluid>
        <p>{{ $t('form.size')}}</p>
        <b-form-file
          multiple
          :file-name-formatter="formatNames"
          @change="upload($event)"
          class="files"
        ></b-form-file>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('form.FileName') }}</th>
              <th scope="col">{{ $t('form.View') }}</th>
              <th scope="col">{{ $t('form.Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(imagen, index) in imagenesRoad" :key="index">
              
              <td>
                {{ imagen.filename }}
              </td>
              <td>
                <div>
                    <b-button v-b-modal="'modal-' + index">view</b-button>

                    <b-modal size="lg" :id="'modal-'+ index" :title="imagen.filename">
                      <img :src="'data:image/jpeg;base64,' + imagen.base_64_file" alt="Img" :style="{width: '100%'}"/>

                    </b-modal>
                  </div>
              </td>
              <td>
                  <!-- Botón para borrar -->
                  <button @click="borrarImagen(index)" class="btn btn-danger">
                    {{ $t('form.Delete') }}
                  </button>
                
              </td>
            </tr>
          </tbody>
        </table>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="secundary"
            size="sm"
            class="float-right"
            @click="show=false"
          >
            {{ $t('proposal.noProposal.buttonCancel') }}
          </b-button>
          <b-button
            variant="success"
            size="sm"
            class="float-right"
            @click="saveImages"
          >
            {{ $t('proposal.noProposal.buttonConfirm') }}
          </b-button>
          
        </div>
      </template>
    </b-modal>
    
  </div>
</template>

<script>

export default {
  name: "EditorSlideUpload",
  props: {
    color: {
      type: String,
      default: "#1890ff",
    },
  },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [],
      show: false,
      textNoProposal: "",
      imagenesRoad: [],
      
    };
  },
  methods: {

    borrarImagen: function (index) {
      // Borramos de la lista
      this.imagenesRoad.splice(index, 1);
    },
    async progressUpload(file, listImagenes, index) {
      if (file.type.slice(0, 5) === "image") {
        const reader = new FileReader();
        let binary, base_64_file;
        reader.readAsBinaryString(file);
        reader.addEventListener(
          "loadend",
          function () {
            binary = reader.result; // binary data (stored as string), unsafe for most actions
            base_64_file = btoa(binary); // base64 data, safer but takes up more memory
            listImagenes.push({ posicion: index, filename: file.name, base_64_file });
          },
          false
        );
      }
    },
    upload(event) {
      const files = Array.from(event.target.files);
      let list = this.imagenesRoad;
      files.forEach((el, index) => this.progressUpload(el, list, list.length + index  + 1));
      setTimeout(()=>{
        this.imagenesRoad = list
        },300)
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} files selected`;
    },
    saveImages() {
      let arr = this.imagenesRoad.map(el => `data:image/jpeg;base64,${el.base_64_file}`);
      this.$emit("successCBK", arr);
      this.imagenesRoad = []
      this.show=false;
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-slide-upload {
  margin-bottom: 20px;
  /*/deep/*/ .el-upload--picture-card {
    width: 100%;
  }
}
</style>
