import { render, staticRenderFns } from "./EditorImage.vue?vue&type=template&id=fdc7a65a&scoped=true"
import script from "./EditorImage.vue?vue&type=script&lang=js"
export * from "./EditorImage.vue?vue&type=script&lang=js"
import style0 from "./EditorImage.vue?vue&type=style&index=0&id=fdc7a65a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdc7a65a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/node-apps/dev-im.ingenes.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fdc7a65a')) {
      api.createRecord('fdc7a65a', component.options)
    } else {
      api.reload('fdc7a65a', component.options)
    }
    module.hot.accept("./EditorImage.vue?vue&type=template&id=fdc7a65a&scoped=true", function () {
      api.rerender('fdc7a65a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/EditorForm/components/EditorImage.vue"
export default component.exports