var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "b-button",
        {
          attrs: { size: "sm", variant: "info" },
          on: {
            click: function($event) {
              _vm.show = true
            }
          }
        },
        [
          _c("b-icon", {
            attrs: { icon: "cloud-download", "aria-hidden": "true" }
          }),
          _vm._v(_vm._s(_vm.$t("form.uploadImage")))
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: this.$i18n.t("form.uploadImage"),
            centered: "",
            "header-bg-variant": "info",
            "header-text-variant": "light",
            "body-bg-variant": "light"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "secundary", size: "sm" },
                          on: {
                            click: function($event) {
                              _vm.show = false
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("proposal.noProposal.buttonCancel")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "success", size: "sm" },
                          on: { click: _vm.saveImages }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("proposal.noProposal.buttonConfirm")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("form.size")))]),
              _c("b-form-file", {
                staticClass: "files",
                attrs: { multiple: "", "file-name-formatter": _vm.formatNames },
                on: {
                  change: function($event) {
                    return _vm.upload($event)
                  }
                }
              }),
              _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("form.FileName")))
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("form.View")))
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(_vm._s(_vm.$t("form.Actions")))
                    ])
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.imagenesRoad, function(imagen, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(" " + _vm._s(imagen.filename) + " ")]),
                      _c("td", [
                        _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal",
                                    value: "modal-" + index,
                                    expression: "'modal-' + index"
                                  }
                                ]
                              },
                              [_vm._v("view")]
                            ),
                            _c(
                              "b-modal",
                              {
                                attrs: {
                                  size: "lg",
                                  id: "modal-" + index,
                                  title: imagen.filename
                                }
                              },
                              [
                                _c("img", {
                                  style: { width: "100%" },
                                  attrs: {
                                    src:
                                      "data:image/jpeg;base64," +
                                      imagen.base_64_file,
                                    alt: "Img"
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            on: {
                              click: function($event) {
                                return _vm.borrarImagen(index)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("form.Delete")) + " ")]
                        )
                      ])
                    ])
                  }),
                  0
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }